import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from '@mui/material';
import { useAuth, useAdmin } from 'hooks';
import { teamUrl, isDevelopment } from 'helpers';
import {
  BarChartIcon, TeamIcon, RoleIcon, RoomIcon, SessionIcon, LibraryIcon,
  RecordingIcon, WebhookIcon, DocumentationIcon, TokenIcon, SystemIcon
} from 'components/Icons/Svg';

const SideMenu = () => {
  const { auth: { last_team: team } } = useAuth();
  const { isAdminPage, isSuperAdmin, isSales } = useAdmin();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pages = {
    admin: [
      {
        name: 'statistics',
        url:  '/admin/statistics',
        icon: <BarChartIcon />
      },
      {
        name: 'allTeams',
        url:  '/admin/teams',
        path: '/admin/teams/*',
        icon: <TeamIcon />,
        disabled: !isSuperAdmin && !isSales
      },
      {
        name: 'nonVerifiedAccounts',
        url:  '/admin/accounts',
        icon: <TeamIcon />,
        disabled: !isSuperAdmin && !isSales
      },
      {
        name: 'userManagement',
        url:  '/admin/users',
        path: '/admin/users/*',
        icon: <TeamIcon />,
        disabled: !isSuperAdmin
      },
      {
        name: 'satisfactionSurveys',
        url:  '/admin/surveys',
        icon: <RoleIcon />
      }
    ],
    frontend: [
      {
        name: 'rooms',
        url:  `/teams/${team.id}/rooms`,
        path: ['/teams/:teamId/rooms/*', '/default-room-settings'],
        icon: <RoomIcon />
      },
      {
        name: 'sessions',
        url:  `/teams/${team.id}/sessions`,
        path: '/teams/:teamId/sessions/*',
        icon: <SessionIcon />
      },
      {
        name: 'team',
        url:  '/team',
        path: '/team/*',
        icon: <TeamIcon />
      },
      {
        name: 'roles',
        url:  `/teams/${team.id}/roles`,
        path: '/teams/:teamId/roles/*',
        icon: <RoleIcon />
      },
      {
        name: 'libraries',
        url:  `/teams/${team.id}/libraries`,
        path: '/teams/:teamId/libraries/*',
        disabled: !isDevelopment(),
        icon: <LibraryIcon />
      },
      {
        name: 'recordings',
        url:  `/teams/${team.id}/recordings`,
        path: '/teams/:teamId/recordings/*',
        disabled: !team.plan.can_record,
        icon: <RecordingIcon />
      },
      {
        name: 'webhooks',
        url:  `/teams/${team.id}/webhooks`,
        path: '/teams/:teamId/webhooks/*',
        icon: <WebhookIcon />
      },
      {
        name: 'tokens',
        url:  `/teams/${team.id}/tokens`,
        path: '/teams/:teamId/tokens',
        icon: <TokenIcon />,
        disabled: team.role !== 'ADMIN'
      },
      {
        name: 'statistics',
        url:  '/statistics',
        icon: <BarChartIcon />
      },
      {
        name: 'documentation',
        url:  '/documentation',
        icon: <DocumentationIcon />
      },
      {
        name: 'systemCheck',
        url:  teamUrl(team, 'systemcheck'),
        icon: <SystemIcon />
      }
    ]
  };

  const menuPages = pages[isAdminPage ? 'admin' : 'frontend'];

  const handleClick = (e, url) => {
    e.preventDefault();
    if (url.indexOf('://') > 0) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const page = menuPages.find((item) => {
    const path = item.path || item.url;
    if (Array.isArray(path)) {
      return path.find((path) => matchPath({ path }, pathname));
    }
    return matchPath({ path }, pathname);
  });

  return (
    <Tabs className="SideMenu" value={page?.name || false} orientation="vertical">
      {menuPages.filter((item) => !item.disabled).map((item, index) => (
        <Tab
          sx={{ mt: index ? 1 : 0 }}
          icon={item.icon}
          iconPosition="start"
          key={`tab-${item.name}`}
          onClick={(e) => handleClick(e, item.url)}
          href={item.url}
          value={item.name}
          label={t(item.name)}
        />
      ))}
    </Tabs>
  );
};

export default SideMenu;
