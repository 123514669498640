import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { alpha } from '@mui/material/styles';

const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1680
    }
  },
  palette: {
    text: {
      primary: '#323E66',
      secondary: '#808699',
      black: '#000'
    },
    primary: {
      main: '#3771e0',
      required: '#EF6859'
    },
    secondary: {
      main: '#EFEFEF',
      contrastText: '#323E66'
    },
    error: {
      main: '#f00',
      contrastText: '#ffffff'
    },
    success: {
      main: '#23d366',
      contrastText: '#ffffff'
    },
    grayscale: {
      main: '#797979',
      contrastText: '#ffffff',
      dark: '#000000'
    },
    blue: {
      main: '#EBF1FC',
      dark: '#323f66',
      contrastText: '#adb2c2'
    },
    light: {
      main: '#ffffff',
      dark: '#EBF1FC',
      contrastText: '#323f66'
    },
    divider: '#c5c5c5',
    grey: {
      300: '#b4aebf',
      400: '#c5c5c5',
      600: '#797979',
      700: '#545557'
    },
    action: {
      selectedOpacity: 0.1
    },
    contrastThreshold: 4.5
  },
  typography: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      '"Liberation Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"'
    ].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '2rem',
      letterSpacing: 'normal'
    },
    h2: {
      fontWeight: 400,
      fontSize: '1.5rem',
      letterSpacing: 'normal'
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.4rem',
      letterSpacing: 'normal'
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.25rem',
      letterSpacing: 'normal'
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.15rem',
      letterSpacing: 'normal'
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.125rem',
      letterSpacing: 'normal'
    },
    header: {
      fontSize: '2.5rem',
      fontWeight: 600
    },
    subheader: {
      fontSize: '2.5rem',
      fontWeight: 600
    },
    subtitle1: {
      fontWeight: 600,
      letterSpacing: 'normal',
      marginTop: '0.35rem',
      marginBottom: '0.5rem'
    },
    subtitle2: {
      letterSpacing: 'normal'
    },
    body1: {
      lineHeight: 1.4,
      letterSpacing: 'normal'
    },
    body2: {
      letterSpacing: 'normal',
      lineHeight: 1.2
    },
    button: {
      letterSpacing: 'normal',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 400
    },
    caption: {
      letterSpacing: 'normal'
    },
    overline: {
      letterSpacing: 'normal'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '*': {
          '.FormContainer': {
            maxWidth: '1060px'
          },
          '.DetailsContainer': {
            maxWidth: '700px'
          },
          '.Notes': {
            backgroundColor: theme.palette.blue.main,
            borderRadius: theme.spacing(0.5),
            padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
            display: 'inline-block',
            fontSize: '0.875rem'
          },
          '.NoWrap': {
            whiteSpace: 'nowrap'
          },
          '.WordBreak': {
            wordBreak: 'break-all'
          },
          '.StickyBox': {
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            alignSelf: 'start',
            backgroundColor: '#ffffff'
          },
          small: {
            fontSize: '75%'
          }
        }
      })
    },
    MuiSelect: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          minWidth: '200px'
        },
        standard: {
          '&:focus': {
            backgroundColor: '#ffffff'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          }
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '& span': {
            display: 'block'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        InputLabelProps:{
          shrink: true
        }
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          },
          '& .MuiInputBase-input': {
            [theme.breakpoints.down('lg')]: {
              fontSize: '0.875rem'
            },
            ...(ownerState.size === 'medium' && {
              paddingTop: 11.5,
              paddingBottom: 11.5,
              [theme.breakpoints.down('lg')]: {
                fontSize: '0.875rem'
              }
            })
          },
          '& .ReadOnly': {
            '&.MuiInputBase-formControl': {
              backgroundColor: '#F0F0F0',
              color: '#000'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            }
          }
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiChip-deleteIcon, & .MuiChip-deleteIcon:hover': {
            color: theme.palette.text.primary
          }
        })
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.SideMenu, &.Navigation': {
            '& .MuiTab-root': {
              textTransform: 'none',
              alignItems: 'center',
              justifyContent: 'start',
              whiteSpace: 'nowrap',
              minHeight: 0,
              color: theme.palette.text.primary,
              [theme.breakpoints.down('lg')]: {
                '& .MuiSvgIcon-root': {
                  fontSize: '1.25rem'
                },
                fontSize: '0.9rem'
              }
            },
            '& button:hover, & a:hover, & .Mui-selected': {
              color: theme.palette.primary.main
            }
          },
          '&.Navigation .MuiTab-root': {
            paddingLeft: 0,
            alignItems: 'start'
          },
          '&.SideMenu': {
            borderRight: `1px solid ${theme.palette.divider}`,
            minWidth: '130px',
            [theme.breakpoints.up('sm')]: {
              minWidth: '140px'
            },
            [theme.breakpoints.up('md')]: {
              minWidth: '200px'
            },
            [theme.breakpoints.up('xl')]: {
              minWidth: '250px'
            },
            '& button:hover, & a:hover': {
              borderRight: `2px solid ${theme.palette.primary.main}`
            },
            '& .MuiTab-root': {
              paddingTop: theme.spacing(0.75),
              paddingBottom: theme.spacing(0.75),
              [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
              },
              [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(3)
              },
              [theme.breakpoints.up('xl')]: {
                paddingLeft: theme.spacing(3.5)
              },
              '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(2),
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              }
            }
          }
        })
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: 'contained',
        color: 'primary'
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          },
          ...(ownerState.variant === 'contained' && ownerState.color === 'secondary' && {
            '&:hover': {
              color: '#ffffff'
            }
          }),
          whiteSpace: 'nowrap'
        })
      },
      variants: [
        {
          props: {
            variant: 'link'
          },
          style: ({ theme }) => ({
            padding: 0,
            display: 'inline',
            color: theme.palette.primary.main,
            textTransform: 'none',
            textDecoration: 'underline',
            textDecorationColor: alpha(theme.palette.primary.light, 0.5),
            verticalAlign: 'initial',
            minWidth: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              textDecorationColor: theme.palette.primary.main
            }
          })
        }
      ]
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiDialog-paper': {
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(1)
            }
          }
        })
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          justifyContent: 'flex-end',
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          flexWrap: 'wrap',
          '& .MuiButton-text': {
            textTransform: 'uppercase',
            fontWeight: 600
          },
          '&.MuiPickersLayout-actionBar': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
          }
        })
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '1.25rem'
          },
          color: '#000',
          fontSize: '1.5rem',
          lineHeight: 1.25,
          marginTop: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5)
        })
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#000'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          },
          color: '#000'
        })
      }
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.TableHover': {
            '& .MuiTableBody-root': {
              '&::before': {
                content: '""',
                height: '10px',
                lineHeight: 0,
                color: 'white',
                display: 'table-row'
              }
            },
            '& .MuiTableRow-root.greyout': {
              '& .MuiTableCell-root': {
                color: theme.palette.grey[500],
                '& .MuiLink-root': {
                  color: alpha(theme.palette.primary.main, 0.65)
                }
              }
            },
            '& .MuiTableRow-root.warning': {
              '& .MuiTableCell-root': {
                backgroundColor: '#ffebee'
              }
            },
            '& .MuiTableRow-root:not(.NoHover)': {
              '& .ShowOnHover:not(.Mui-checked)': {
                visibility: 'hidden'
              },
              '& .MuiTableCell-root': {
                borderBottom: '1px solid #fff'
              },
              '& .MuiTableCell-root:first-of-type': {
                borderRadius: '5px 0 0 5px'
              },
              '& .MuiTableCell-root:last-of-type': {
                borderRadius: '0 5px 5px 0'
              },
              '& .MuiChip-label': {
                overflow: 'visible'
              },
              '&:hover .MuiTableCell-root, &.active .MuiTableCell-root': {
                backgroundColor: theme.palette.blue.main
              },
              '&:hover .MuiTableCell-root .ShowOnHover': {
                visibility: 'visible'
              }
            }
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
          '& .MuiTypography-root:not(.MuiMenuItem-root)': {
            fontSize: 'inherit'
          },
          '& .MuiIconButton-root': {
            padding: 0
          },
          '& .MuiCheckbox-root': {
            padding: 0
          },
          '&.Checkbox': {
            width: 20,
            paddingRight: 4
          }
        },
        sizeSmall: {
          border: 0
        },
        sizeMedium: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          },
          fontSize: '1rem'
        }),
        head: ({ theme }) => ({
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          backgroundColor: theme.palette.blue.main,
          '& .MuiTypography-root': {
            fontWeight: 500
          }
        })
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          }
        }),
        body2: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.85rem'
          }
        }),
        h2: ({ theme }) => ({
          marginBottom: theme.spacing(3),
          [theme.breakpoints.down('lg')]: {
            fontSize: '1.35rem'
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '1.25rem'
          },
          '&.ProductHeader, &.PublicHeader': {
            fontWeight: 'bold',
            fontSize: '1.645rem'
          },
          '&.ProductHeader': {
            [theme.breakpoints.down('xxl')]: {
              fontSize: '1.5rem'
            },
            [theme.breakpoints.down('xl')]: {
              fontSize: '1.35rem'
            }
          },
          '&.PublicHeader': {
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              fontWeight: 'normal'
            }
          }
        }),
        header: ({ theme }) => ({
          [theme.breakpoints.down('xl')]: {
            fontSize: '2.25rem'
          },
          [theme.breakpoints.down('lg')]: {
            fontSize: '2rem'
          }
        })
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3)
        })
      }
    },
    MuiStack: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.PageHeader': {
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              gap: theme.spacing(3),
              alignItems: 'center'
            },
            alignItems: 'start',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
            '& .MuiTypography-h2': {
              margin: 0
            },
            '& .MuiButton-startIcon': {
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }
          }
        })
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiFormControlLabel-root': {
            [theme.breakpoints.up('sm')]: {
              marginRight: theme.spacing(4)
            },
            [theme.breakpoints.up('lg')]: {
              marginRight: theme.spacing(6.25)
            }
          }
        })
      }
    },
    MuiTableSortLabel:  {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.main
          }
        })
      }
    },
    MuiSwitch:  {
      styleOverrides: {
        root: ({ theme }) => ({
          width: 38,
          height: 22,
          marginTop: theme.spacing(1),
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 3,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
              }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.grey[100]
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3
            }
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16
          },
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            backgroundColor: theme.palette.text.primary,
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500
            })
          }
        })
      }
    }
  }
});

const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={appTheme}>
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
